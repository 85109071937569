/* Colores */
:root {
  --primary-red: #e53935;
  --primary-green: #43a047;
  --primary-yellow: #ffeb3b;
  --primary-blue: #0d47a1;
  --light-yellow: #fff59d;
  --text-black: #212121;
  --text-white: #ffffff;
}

/* Fuente general */
body {
  font-family: 'Poppins', sans-serif, Arial, sans-serif;
  color: var(--text-black);
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

/* Header y Footer con animación de fondo degradado */
header, footer {
  background: linear-gradient(90deg, var(--primary-blue), var(--primary-red));
  background-size: 200% 200%;
  animation: gradientAnimation 5s ease infinite;
  color: white;
  padding: 1rem;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Navegación General */
nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 0;
  margin: 0;
  flex-direction: row;
}

nav ul li a {
  color: var(--text-white);
  text-decoration: none;
  font-size: 1.2rem;
  transition: transform 0.3s, color 0.3s;
}

nav ul li a:hover {
  color: var(--primary-yellow);
  transform: scale(1.1);
}

/* Footer */
footer {
  text-align: center;
  padding: 1.5rem 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

/* Botones Generales */
button {
  background-color: var(--primary-red);
  color: var(--text-white);
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  background-color: var(--primary-green);
  transform: scale(1.05);
}

/* Secciones */
.section-highlight {
  background-color: var(--light-yellow);
  padding: 2rem;
  text-align: center;
}

h1, h2, h3 {
  color: var(--primary-red);
}

/* Contenedores con sombra */
.container {
  background-color: white;
  padding: 2rem;
  margin: 2rem 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* Formulario de contacto */
form label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--primary-blue);
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: white;
}

form input, form textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--primary-blue);
  border-radius: 5px;
}

form button {
  background-color: var(--primary-red);
  color: var(--text-white);
}

form button:hover {
  background-color: var(--primary-green);
}

/* Sección con parallax */
.section-parallax,
.background1 {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 2rem;
}

.background3 {
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 2rem;
}

.section-parallax {
  background-image: url('../assets/background-image.jpg');
}

.background1, .background3 {
  background-image: url('../assets/background2.jpg');
}

.background1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.background1 h1, .background1 p {
  position: relative;
  z-index: 2;
  font-size: 3rem;
}

.background1 p {
  font-size: 1.5rem;
}

/* Cards con efecto hover */
.card {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05) rotate(3deg);
}

/* Servicios destacados */
.services {
  padding: 4rem 2rem;
  background-color: #f4f4f4;
  text-align: center;
}

.services h2 {
  font-size: 2.5rem;
  color: var(--primary-red);
  margin-bottom: 2rem;
}

.services-container {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  flex-wrap: wrap;
}

.card h3 {
  font-size: 1.5rem;
  color: var(--primary-blue);
  margin-bottom: 1rem;
}

.card p {
  font-size: 1rem;
  color: var(--text-black);
}

/* Noticias recientes */
.news {
  padding: 4rem 2rem;
  background-color: #ffffff;
  text-align: center;
}

.news h2 {
  font-size: 2.5rem;
  color: var(--primary-red);
  margin-bottom: 2rem;
}

.news-container {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  flex-wrap: wrap;
}

.news-item {
  background-color: #f4f4f4;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  text-align: left;
  transition: transform 0.3s ease;
}

.news-item h3 {
  font-size: 1.5rem;
  color: var(--primary-blue);
  margin-bottom: 1rem;
}

.news-item p {
  font-size: 1rem;
  color: var(--text-black);
}

.news-item:hover {
  transform: scale(1.05);
}

/* Diseño responsivo */
@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
    gap: 1rem;
  }

  .container {
    padding: 1rem;
  }

  button {
    width: 100%; /* Botones más grandes en móvil */
  }

  .background1 {
    height: 50vh;
  }

  .background1 h1 {
    font-size: 2rem;
  }

  .background1 p {
    font-size: 1rem;
  }

  .services-container, .news-container {
    flex-direction: column;
    align-items: center;
  }

  .card, .news-item {
    max-width: 100%;
  }
}

/* Menú hamburguesa */
.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 2rem;
  color: white;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  nav ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: var(--primary-blue);
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  nav ul.active {
    height: auto;
    padding: 1rem 0;
  }

  nav ul li {
    padding: 1rem 0;
    text-align: center;
  }

  nav ul img {
    margin-bottom: 1rem;
  }
}

/* Header */
header {
  background: linear-gradient(90deg, var(--primary-blue), var(--primary-red));
  background-size: 200% 200%;
  animation: gradientAnimation 5s ease infinite;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: center;
  z-index: 1000; /* Asegura que el header esté por encima del contenido */
  position: relative; /* Necesario para que z-index funcione */
}

/* Menú de navegación */
nav ul {
  list-style: none;
  display: flex;
  gap: 2rem;
  padding: 0;
  margin: 0;
  align-items: center;
  z-index: 1001; /* El menú también debería tener un z-index alto */
}

/* Fondo parallax */
.background1 {
  position: relative;
  background-image: url('../assets/background2.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  color: white;
  z-index: 1; /* Asegura que el fondo esté por debajo del header */
}

.background1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  z-index: 0; /* Asegura que el overlay no interfiera con el contenido superior */
}

/* Sección Sobre Nosotros */
.about-us {
  background: url('../assets/background1.jpg') no-repeat center center; /* Imagen de fondo */
  background-size: cover; /* Asegura que la imagen cubra toda la sección */
  padding: 4rem 2rem;
  color: white;
  text-align: center;
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.6); /* Fondo semitransparente para mejorar la legibilidad */
  border-radius: 10px;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

/* Texto de la sección Sobre Nosotros */
.about-text {
  flex: 1;
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: left;
  color: #fff;
}

/* Imagen en la sección Sobre Nosotros */
.about-image {
  flex: 1;
}

.about-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Sombra suave */
}

/* Diseño responsivo */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }

  .about-image, .about-text {
    flex: unset;
    width: 100%;
  }

  .about-text {
    font-size: 1rem;
    text-align: justify;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* Relación de aspecto 16:9 */
  height: 0;
  overflow: hidden;
  max-width: 80%;
  background-color: #000; /* Fondo negro para videos que no se han cargado */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; /* Sin bordes */
}
